import { adjustAddress } from '@sasagase/adjust-address';
import stringify from "csv-stringify";
import Encoding from "encoding-japanese";
import { Barcode, InvoiceData, InvoiceDataMap, Shipping } from '../types';
import { useCSV } from './';
import { NameAddress } from './useCSV';

interface UseMethods {
	getOptions: () => stringify.Options;
	getRecords: (shippings: Shipping[], invoiceDataMap: InvoiceDataMap, barcode: Barcode) => Record<string, string|number>[];
}

export const useSagawaCSV = (): UseMethods => {

	const {
		parseDate,
		parseTimezone,
		parseDeliveryDate,
	} = useCSV();

	const getOptions = (): stringify.Options => {
		return {
			columns: [
				"住所録コード",
				"お届け先電話番号", "お届け先郵便番号", "お届け先住所１（必須）", "お届け先住所２", "お届け先住所３",
				"お届け先名称１（必須）", "お届け先名称２",
				"お客様管理番号", "お客様コード",
				"部署ご担当者名称", "荷送人電話番号",
				"ご依頼主電話番号", "ご依頼主郵便番号","ご依頼主住所１", "ご依頼主住所２",
				"ご依頼主名称１", "ご依頼主名称２",
				"荷姿", "品名１", "品名２", "品名３", "品名４", "品名５", "出荷個数",
				"スピード指定", "クール便指定", "配達日", "配達指定時間帯", "配達指定時間（時分）",
				"代引金額", "消費税", "決済種別", "保険金額", "保険金額印字",
				"指定シール１", "指定シール２", "指定シール３",
				"営業所受取", "SRC区分", "営業所受取営業所コード", "元着区分",
			],
			header: false,
			quoted: true,
			quoted_empty: true,
		};
	};

	const getRecords = (shippings: Shipping[], invoiceDataMap: InvoiceDataMap, barcode: Barcode) => {
		/** CSVに出力しない配送方法のリスト */
		const IGNORE_METHOD = ["大型宅配便", "大型1", "大型2", "大型メーカー直送便", "国際配送"];

		const records = shippings.map(shipping => {
			if (IGNORE_METHOD.includes(shipping.method)) {
				return null;
			}

			const cod = shipping.cod ? shipping.cod : "";

			const dest = shipping.dest;

			const parsedDelivery = parseDeliveryDate(shipping.delivery_date);
			const pDate = parseDate(parsedDelivery.date);
			const pTime = parseTimezone(parsedDelivery.timezone);
			const pShip = parseDate(parsedDelivery.shipping || new Date());

			// 配達日 (半角数字8桁)
			// 20210201（2021年2月1日を指定する場合）
			const deliveryDate = pDate ? `${pDate.year}${pDate.mon}${pDate.day}` : parsedDelivery.date;
			// 配達指定時間帯(半角数字2桁)
			// timezone[begin][end]
			const timezone: Record<string, Record<string, string>> = {
				'08': { '12': '01' },	// 01： 午前中 (5時間帯/6時間帯)
				'12': { '14': '12' },	// 12： 12:00～14:00 (5時間帯/6時間帯)
				'14': { '16': '14' },	// 14： 14:00～16:00 (5時間帯/6時間帯)
				'16': { '18': '16' },	// 16： 16:00～18:00 (5時間帯/6時間帯)
				'18': { '20': '18', '21': '04' },	// 18： 18:00～20:00 (6時間帯), 04： 18:00～21:00 (5時間帯)
				'19': { '21': '19' },	// 19： 19:00～21:00 (6時間帯)
			};
			const deliveryTimezone = pTime && timezone?.[pTime.begin]?.[pTime.end] ?
				timezone[pTime.begin][pTime.end] :
				pTime && pTime.am ? '01' : parsedDelivery.timezone;
			// 出荷予定日 (半角数字8桁)
			// 20210201（2021年2月1日を指定する場合）
			const shippingDate = pShip ? `${pShip.year}${pShip.mon}${pShip.day}` : parsedDelivery.shipping;
			// 指定シール（３つまで）
			const sticker: string[] = [];
			if (deliveryDate && !deliveryTimezone) {
				sticker.push('005');	// 005: 指定日配達サービス
			} else if (deliveryTimezone == '04') {
				sticker.push('007');	// 007: 時間帯指定サービス(※1) （※1）「５時間帯」用のシールコードとなります。
			} else if (deliveryTimezone) {
				sticker.push('019');	// 019: 時間帯指定サービス(※2) （※2）「６時間帯（オプション）」用のシールコードとなります。
			} else {
				// 配達日・配達指定時間帯の指定が無い場合は指定シールは下記２つ
			}
			// 下記は固定
			sticker.push(
				'011',	// 011: 取扱注意
				'013',	// 013: 天地無用
			);

			const [address1, address2, address3] = adjustAddressGeneral(dest);

			const invoiceData = invoiceDataMap[shipping.orders[0].method];

			const client: NameAddress = getClientAddress(shipping, invoiceData);
			const [clientAddress, clientSubaddress] = adjustAddressGeneral(client);
			return {
				"お届け先電話番号": dest.phone,
				"お届け先郵便番号": dest.zip,
				"お届け先住所１（必須）": address1,
				"お届け先住所２": address2,
				"お届け先住所３": address3,
				"お届け先名称１（必須）": dest.name,
				"お届け先名称２": "",
				"お客様管理番号": `${barcode.shippingPrefix}${shipping.id}`,	// 半角英数16桁
				"お客様コード": "",
				"部署ご担当者名称": "",
				"荷送人電話番号": "",
				"ご依頼主電話番号": client.phone,
				"ご依頼主郵便番号": client.zip,
				"ご依頼主住所１": clientAddress,
				"ご依頼主住所２": clientSubaddress,
				"ご依頼主名称１": client.name,
				"ご依頼主名称２": "",
				"荷姿": "001",	// 001: 箱類
				"品名１": invoiceData.shipping.contents,
				"品名２": "",
				"品名３": "",
				"品名４": "",
				"品名５": "",
				"出荷個数": "",
				"スピード指定": "000",	// 000: 飛脚宅配便
				"クール便指定": "001",	// 001: 指定なし
				"配達日": deliveryDate ?? "",
				"配達指定時間帯": deliveryTimezone ?? "",
				"配達指定時間（時分）": "",
				"代引金額": cod,
				"消費税": dest.codtax,
				"決済種別": cod ? "2" : "0",	// 0: 指定なし, 1: 全て可, 2: 現金のみ, 5: ﾃﾞﾋﾞｯﾄ･ｸﾚｼﾞｯﾄ
				"保険金額": "",
				"保険金額印字": "0",	// 0: 印字しない, 1: 印字する
				"指定シール１": sticker[0] ?? "",
				"指定シール２": sticker[1] ?? "",
				"指定シール３": sticker[2] ?? "",
				"営業所受取": "0",	// 0: 通常出荷
				"SRC区分": "0",	// 0: 指定なし
				"営業所受取営業所コード": "",
				"元着区分": "1",	// 1: 元払
				"出荷予定日": shippingDate,
			};
		});
		return records.filter(Boolean) as Record<string, string|number>[];
	};

	const getClientAddress = (shipping: Shipping, invoiceData: InvoiceData) => {
		const orderers: NameAddress[] = shipping.orders.map(order => {
			// ordererの各プロパティは手動登録の注文だと配列のことがあるので変換する
			const ret: Record<string, string> = {};
			for (const [key, val] of Object.entries(order.orderer)) {
				ret[key] = (val || "").toString();
			}
			return ret;
		});

		// 注文者と送付先の名前と住所が同じ場合に送付元の情報を「初期値(ぷりふあ人形)」に変更
		// 注文者と送付者の住所が同じかつ名前が異なる場合に送付元の情報を「ぷりふあ人形 XXX 様御依頼分」に変更
		// 注文者の住所情報がない場合は初期値の住所を使用して、名前だけ注文者を使用する =>「ぷりふあ人形 XXX 様御依頼分」
		// それ以外の場合は注文者の情報を使用する
		const client: NameAddress|undefined = orderers.find(orderer => {
			const ordererAddress = adjustAddressGeneral(orderer).join('');
			const shippingAddress = adjustAddressGeneral(shipping.dest).join('');
			return !(
				spaceIgnoreEqual(orderer.name, shipping.dest.name) &&
				spaceIgnoreEqual(ordererAddress, shippingAddress)
			);
		});
		if (!client) {
			return invoiceData.client;
		} else if (isEmptyAddress(client) || isDiffOnlyName(client, shipping.dest)) {
			let name;
			if (client.name) {
				name = [invoiceData.names.before, client.name.replace(/\s+/g, ' '), invoiceData.names.after].join('');
			}
			return {
				...invoiceData.client,
				name: name || invoiceData.client.name,
			};
		} else {
			return client;
		}
	};

	const spaceIgnoreEqual = (a = "", b = ""): boolean => {
		return a.replace(/\s/g, "") == b.replace(/\s/g, "");
	};

	const isEmptyAddress = (addr: NameAddress): boolean => {
		return [addr.country, addr.pref, addr.city, addr.address, addr.subaddress].join('') == '';
	};

	const isDiffOnlyName = (addr1?: NameAddress, addr2?: NameAddress): boolean => {
		if (!addr1 || !addr2) {
			return false;
		}
		return !spaceIgnoreEqual(addr1.name, addr2.name) && spaceIgnoreEqual(adjustAddressGeneral(addr1).join(''), adjustAddressGeneral(addr2).join(''));
	};

	/**
	 * 住所と建物名等の区切り位置を調整する。
	 *
	 * @param country 国名
	 * @param pref 都道府県(4文字)
	 * @param city 市区郡町村(12文字)
	 * @param address 字・番地(16文字)
	 * @param subaddress 建物名等(16文字)
	 * @param company 会社名(25文字)
	 * @param section 部門名(25文字)
	 * @param withCompany 戻り値に会社名・部門名を含めるか
	 * @returns [住所(頭から16文字), 住所(次の16文字), 住所(ここまでに入り切らなかった16文字), 会社名(25文字 or 0文字), 部門名(25文字 or 0文字)]
	 */
	const adjustAddressGeneral = ({ country = "", pref = "", city = "", address = "", subaddress = "", company = "", section = "" }, withCompany = false) => {
		// wowmaの住所情報が`[都道府県] [それ以降]`という1つの文字列なので、ヤマト用に都道府県名の後のスペースを消す必要がある
		if (!pref && city) {
			[pref, city] = splitPref(city);
		} else if (!pref && address) {
			[pref, address] = splitPref(address);
		}

		// useHale=falseの場合、全角変換したもので調整する
		const values = [ address, subaddress, company, section ].map(str => Encoding.toZenkakuCase(str));
		const limits = [
			32 - (pref ? 8 : 0) - (city ? 24 : 0),
			32,
			32,
			withCompany ? 50 : 0,
			withCompany ? 50 : 0,
		];
		const [ _address, _subaddress, _company, _section ] = adjustAddress(values, limits, 'shift_jis', false);
		return [country + pref + city + _address, _subaddress, _company, _section];
	};

	/**
	 * 文字列を都道府県名とそれ以降に分割する
	 * 都道府県名がなければ空文字列
	 * @returns [都道府県名, それ以降]
	 */
	const splitPref = (addr: string): [string, string] => {
		const parts = /\s*(.{2,3}[都道府県])\s*(.*)\s*$/.exec(addr);
		if (!parts) {
			return ['', addr];
		}
		return [parts[1], parts[2]];
	};

	return {
		getOptions,
		getRecords,
	};
};