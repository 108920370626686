import * as React from 'react';
import { toBR } from '../../../lib';
import { judgeDestAlert, judgeNoshiWrappingAlert } from '../../../lib/judgeAlert';
import { Shipping } from '../../../types';

interface PickingListShippingProps {
	shipping: Shipping;
}

export const PickingListShipping: React.FC<PickingListShippingProps> = (props) => {
	const { shipping: { dest, tracking, orders } } = props;
	if (orders.length > 1) {
		throw new Error();
	}
	const order = orders[0];

	const separaterOrderer = ['日本', ''].includes(order.orderer.country) ? '' : ' ';
	const ordererAddress = [order.orderer.country, order.orderer.pref, order.orderer.city, order.orderer.address, order.orderer.subaddress].join(separaterOrderer);
	const ordererCompany = [order.orderer.company, order.orderer.section].join(separaterOrderer);
	const separaterDest = ['日本', ''].includes(dest.country) ? '' : ' ';
	const destAddress = [dest.country, dest.pref, dest.city, dest.address, dest.subaddress].join(separaterDest);
	const destCompany = [dest.company, dest.section].join(separaterDest);
	const billSlips = [order.detail.receipt ? '領' : '', order.detail.detailedSlip ? '納' : '', order.detail.billSlip ? '請' : ''].filter(str => str !== '').join(",");
	const items = orders.flatMap(order => order.items);

	return (
		<>
			<section className="shipping">
				<h1>受注情報</h1>
				<div>
					<table>
						<tbody>
							<tr>
								<th className="w80">ひとこと<br />メモ</th>
								<td>{ toBR(order.detail.sellerComments) }</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h1>注文者</h1>
				<div>
					<dl className="orderer">
						<dt>氏名</dt><dd>{ order.orderer.name }{ order.orderer.kana ? `（${order.orderer.kana}）` : '' }</dd>
						<dt>住所</dt><dd>〒{ order.orderer.zip }  { ordererAddress }</dd>
						<dt>会社・部署</dt><dd>{ ordererCompany }</dd>
						<dt>電話番号</dt><dd>{ order.orderer.phone }</dd>
					</dl>
				</div>
				<h1>送付先</h1>
				<div>
					<dl className="shippings">
						<dt>氏名</dt><dd>{ dest.name }{ dest.kana ? `（${dest.kana}）` : '' }</dd>
						<dt>住所</dt><dd>〒{ dest.zip }  { destAddress }</dd>
						<dt>会社・部署</dt><dd>{ destCompany }</dd>
						<dt>電話番号</dt><dd>{ dest.phone }</dd>
						<dt>伝票番号</dt><dd className="divide">{ tracking }</dd>
						<dt>配送会社</dt><dd className="divide">{ order.detail.deliveryCompanyName }</dd>
					</dl>
				</div>
				<h1>支払い情報</h1>
				<div>
					<dl className="pay">
						<dt>総請求額</dt><dd className="divide">{ order.detail.requestPrice ? order.detail.requestPrice.toLocaleString() : '-' } 円</dd>
						<dt>支払方法</dt><dd className="divide">{ order.detail.settlementMethodName }</dd>
					</dl>
				</div>
			</section>
			<section className="remarks">
				<h1>備考</h1>
				{order.detail.failedParseRemarks &&
					<p className="bold xl">※備考から配送日時・のし名前・自由記入欄の取得に失敗しました</p>
				}
				<table>
					<tbody>
						<tr>
							<th>のし</th>
							<td rowSpan={2} className="deliveryTime"><p className="bold x2">{ !dest.specifiedDeliveryTimezone.sagawa && <>佐川時間<br />指定不可</> }</p></td>
							<td rowSpan={2} className="noshi"><p className="bold x4">{ judgeNoshiWrappingAlert(order.detail, items) }</p></td>
						</tr>
						<tr>
							<td>
								<p className="mb5"><span className="underline">{ order.detail.noshiAddress }</span></p>
								<p>熨斗下贈り主様お名前：<span className="underline">{ order.detail.noshiName }</span></p>
							</td>
						</tr>
						<tr>
							<th>自由記入欄</th>
							<td rowSpan={2} className="billSlips"><p className="bold x2">{ billSlips }</p></td>
							<td rowSpan={2} className="island"><p className="strong x4">{ judgeDestAlert(dest.zip, dest.pref, dest.city, dest.address, dest.isolatedIsland) }</p></td>
						</tr>
						<tr>
							<td className="note"><p>{ toBR(order.detail.buyerComments) }</p></td>
						</tr>
					</tbody>
				</table>
			</section>
		</>
	);
};