import sleep from "./sleep";

interface RateLimiterOptions {
	/** 最大容量 */
	max: number;
	/** 1の回復に必要なms */
	recoveryTime: number;
	/** 前回からの最小実行間隔 */
	interval: number;
}

export class RateLimiter {
	private max: number;
	private recoveryTime: number;
	private interval: number;

	/** 最終の使用量 */
	private bucket: number;
	/** 使用量回復の基準時間 */
	private recoverDate: number;
	/** 最終実行時間 */
	private lastDate: number;

	constructor(opt: RateLimiterOptions) {
		this.max = opt.max;
		this.recoveryTime = opt.recoveryTime;
		this.interval = opt.interval;

		this.bucket = 0;
		this.recoverDate = Date.now();
		this.lastDate = 0;
	}

	/**
	 * 指定した使用量を消費して足りない場合は待機する。
	 * また、使用量とは別に最終実行からの経過時間が指定の最小実行間隔を下回る場合も待機する。
	 */
	async use(count = 1): Promise<void> {
		// 最終実行からの経過時間に応じて使用量を回復する
		this.recover();

		// 待機中にも並行処理から呼び出される可能性を考慮して、
		// トータルの待機時間を算出して先に待機後の状態を設定する
		const now = Date.now();
		const intervalWait = this.lastDate + this.interval - now;
		const recoveryWait = (this.bucket + count - this.max) * this.recoveryTime;
		const wait = Math.max(0, intervalWait, recoveryWait);

		if (this.bucket <= 0) {
			// 空から使用する場合は回復基準時間をリセットする
			this.recoverDate = now;
		}
		this.bucket += count;
		this.lastDate = now + wait;

		if (wait > 0) {
			await sleep(wait);
		}
	}

	/**
	 * 現在時刻をもとに最終の使用量を再計算する
	 */
	private recover(): void {
		const now = Date.now();
		const elapsed = now - this.recoverDate;

		// 小数の積算を避けるために回復に使用したmsだけ進める
		const recover = Math.min(this.bucket, Math.floor(elapsed / this.recoveryTime));
		this.bucket -= recover;
		this.recoverDate += recover * this.recoveryTime;
	}
}
export default RateLimiter;
