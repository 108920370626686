import { ShippingOrderItem } from '../types/Shipping'

/**
 * 宛先アラート判定
 *    郵便番号,都道府県,郡市区,その他住所から「北海道」,「沖縄県」,「離島」を判定し、それぞれの頭文字を返す
 * @param zip
 * @param pref
 * @param city
 * @param address
 * @param isolatedIslandFlg
 * @returns {string} 北:北海道, 沖:沖縄県, 離:離島
 *
 */
export function judgeDestAlert(zip: string, pref: string, city: string, address: string, isolatedIslandFlg: boolean): string {
	const joinAddress = (pref ?? '').concat(city, address);
	if (joinAddress.indexOf('北海道') >= 0) return '北';
	if (joinAddress.indexOf('沖縄県') >= 0) return '沖';
	return isolatedIslandFlg ? '離' : '';
}

/**
 * のし・ラッピングアラート判定
 * @param detail
 * @returns
 */
export function judgeNoshiWrappingAlert(detail: Record<string, string|boolean>, items: ShippingOrderItem[]): string {
	if (detail.noshiAddress !== '' || detail.noshiName !== '' || items.some(item => item.attributes.includeNoshi)) {
		return 'のし';
	} else if (detail.wrapping || items.some(item => item.attributes.includeWrapping)) {
		return 'ラ';
	}
	return '';
}